var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, TextField, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ControlledInputNumber } from "../Controls/Slider/ControlledInput";
export function SaveSystemDialog({ isOpen, handleClose, handleSaveSystem, numFrames, }) {
    const [wait, setWait] = useState(false);
    const [error, setError] = useState("");
    const [format, setFormat] = useState("");
    const [selection, setSelection] = useState("all");
    const [frameSelection, setFrameSelection] = useState({
        from: 1,
        to: numFrames ? numFrames : 1,
        stride: 1,
    });
    useEffect(() => {
        setFrameSelection({
            from: 1,
            to: numFrames ? numFrames : 1,
            stride: 1,
        });
    }, [numFrames]);
    const handleConfirm = () => __awaiter(this, void 0, void 0, function* () {
        setWait(true);
        let success = false;
        try {
            const _selection = selection.trim() === "" ? "all" : selection.trim();
            yield handleSaveSystem(format, _selection, frameSelection.from, frameSelection.to, frameSelection.stride);
            success = true;
        }
        catch (e) {
            setError(`${e}`);
        }
        setWait(false);
        if (success) {
            setError("");
            // handleClose();
        }
    });
    const handleSelectFormat = (newFormat) => {
        setError("");
        setFormat(newFormat);
    };
    const handleChangeSelection = (newSelection) => {
        setError("");
        setSelection(newSelection);
    };
    const _handleClose = () => {
        setError("");
        setFormat("");
        setSelection("");
        setFrameSelection({
            from: 1,
            to: 1,
            stride: 1,
        });
        handleClose();
    };
    const handleChangeFrames = (newFrom, newTo, newStride) => {
        setFrameSelection({
            from: newFrom,
            to: newTo,
            stride: newStride,
        });
    };
    useEffect(() => {
        if (frameSelection.from > frameSelection.to) {
            setError("'From' must be equal or smaller than 'To'.");
            return;
        }
        else {
            setError("");
        }
    }, [frameSelection.from, frameSelection.to]);
    const trajFormats = ["xtc", "trr"];
    return (_jsxs(Dialog, Object.assign({ open: isOpen, onClose: _handleClose, fullWidth: true }, { children: [_jsxs(DialogTitle, { children: ["Download System", _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: _handleClose, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] }), _jsx(DialogContent, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 2 }, flexDirection: "column" }, { children: [_jsx(FormatSelector, { handleSelect: handleSelectFormat, format: format }), _jsx(SelectionInput, { handleChange: handleChangeSelection, selection: selection }), !!numFrames && numFrames > 1 && trajFormats.includes(format) && (_jsx(SelectFrames, { handleChange: handleChangeFrames, frameSelection: frameSelection, numFrames: numFrames })), !!error && (_jsx(DialogContentText, Object.assign({ component: FormHelperText, error: true }, { children: error })))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: _handleClose }, { children: "cancel" })), _jsx(Button, Object.assign({ variant: "contained", disabled: !!error || wait || !format, onClick: handleConfirm, endIcon: wait ? _jsx(CircularProgress, { size: 21 }) : undefined }, { children: "confirm" }))] })] })));
}
function FormatSelector({ handleSelect, format, }) {
    const acceptedFormats = [
        "pdb",
        "pdbqt",
        "cif",
        "mol2",
        "xtc",
        "sdf",
        "xyz",
        "psf",
        "trr",
        // "crd",
        // "gro",
        // "inpcrd",
        // "binpos",
        // "dcd",
        // "mmtf",
        // "coor",
        // "xsc",
        // "pdb.gz",
        // "xyz.gz",
    ].sort();
    return (_jsx(Autocomplete, { value: format ? format : null, autoHighlight: true, id: "download-file-select-format", options: acceptedFormats, size: "small", sx: { mt: 1, width: "9em" }, onChange: (event, newValue) => {
            handleSelect(newValue ? newValue : "");
        }, renderInput: (params) => _jsx(TextField, Object.assign({}, params, { label: "Format" })) }));
}
const SelectionInput = ({ handleChange, selection, }) => {
    return (_jsx(TextField, { fullWidth: true, label: "Selection", variant: "outlined", value: selection, size: "small", onChange: (e) => handleChange(e.target.value), placeholder: "all" }));
};
const SelectFrames = ({ handleChange, frameSelection, numFrames, }) => {
    return (_jsxs(Box, Object.assign({ sx: { display: "flex", gap: 2 }, flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "Frames" })), _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1 } }, { children: [_jsx(Box, { children: _jsx(ControlledInputNumber, { currVal: frameSelection.from, minVal: 1, maxVal: numFrames, disabled: false, onUpdate: (newFrom) => {
                                handleChange(newFrom, frameSelection.to, frameSelection.stride);
                            }, onlyIntegers: true, step: 1, outlined: true, label: "From" }) }), _jsx(Box, { children: _jsx(ControlledInputNumber, { currVal: frameSelection.to, minVal: 1, maxVal: numFrames, disabled: false, onUpdate: (newTo) => {
                                handleChange(frameSelection.from, newTo, frameSelection.stride);
                            }, onlyIntegers: true, step: 1, outlined: true, label: "To" }) }), _jsx(Box, { children: _jsx(ControlledInputNumber, { currVal: frameSelection.stride, minVal: 1, maxVal: numFrames, disabled: false, onUpdate: (newStride) => {
                                handleChange(frameSelection.from, frameSelection.to, newStride);
                            }, onlyIntegers: true, step: 1, outlined: true, label: "Stride" }) })] }))] })));
};
